import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import CryptrPlugin from "./CryptrPlugin";

Vue.config.productionTip = false;

const cryptrConfig = {
  audience: process.env.VUE_APP_AUDIENCE,
  client_id: process.env.VUE_APP_CLIENT_ID,
  cryptr_base_url: process.env.VUE_APP_CRYPTR_BASE_URL,
  default_locale: process.env.VUE_APP_DEFAULT_LOCALE,
  default_redirect_uri: process.env.VUE_APP_DEFAULT_REDIRECT_URI,
  telemetry: process.env.VUE_APP_CRYPTR_TELEMETRY === 'true',
  tenant_domain: process.env.VUE_APP_TENANT_DOMAIN
};

Vue.use(CryptrPlugin, cryptrConfig);

let vm = new Vue({
  router,
  render: h => h(App)
}).$mount("#app");

console.log("vm");
console.log(vm);
