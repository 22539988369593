import { getCryptrClient } from "./CryptrPlugin";

export const cryptrGuard = (to, from, next) => {
  const cryptr = getCryptrClient();

  const invitationPresence = () => {
    return cryptr.client.canHandleInvitation();
  }

  const signinUnlessAuthenticated = () => {
    // If the user is authenticated, continue with the route
    if (cryptr.isAuthenticated) {
      return next();
    }

    // OR reedirect on the default url
    cryptr.signInWithRedirect();
  };

  // If loading has already finished, check "signinUnlessAuthenticated()"
  if (!cryptr.loading) {
    return signinUnlessAuthenticated();
  }

  // Watch for the loading property to change before we check isAuthenticated
  cryptr.$watch("loading", loading => {
    if (!loading) {
      return !invitationPresence() && !signinUnlessAuthenticated();
    }
  });
};
